
import { BrowserRouter as Router, Route, Routes,useNavigate, useLocation  } from 'react-router-dom';
import CallbackPage from './CallbackPage';
import { useEffect, useState } from 'react';


const NoMatchPage = () => {
  return <div>Nothing to show on this page/path</div>;
};
const App = () => {
  const referrerUrl1 = document.referrer;
  console.log("🚀 ~ fetchData ~ document:", document)
  console.log("🚀 ~ fetchData ~ referrer:", referrerUrl1)
  const [referrerUrl, setReferrerUrl] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  
  console.log("🚀 ~ App ~ referrerUrl:", referrerUrl)
 useEffect(() => {
    // Store the current URL before navigating back
    const currentUrl = location.pathname + location.search;

    const handlePopState = () => {
      // Capture the referrer URL
      const referrer = document.referrer;
      setReferrerUrl(referrer);

      // Restore the original state after capturing referrer
      navigate(currentUrl, { replace: true });
    };

    // Listen for the popstate event which is triggered by history.back()
    window.addEventListener('popstate', handlePopState);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location, navigate]);

  const simulateBackAndCaptureReferrer = () => {
    // Simulate pressing the back button
    navigate(-1);
  };

  return (
    
      <Routes>
        {/* Define your routes */}
        <Route path="/redirects/ipgcallback" element={<CallbackPage />} />
        {/* Other routes */}
        <Route path="*" element={<NoMatchPage />} />
      </Routes>
    
  );
};

export default App;
