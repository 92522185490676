import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner, Alert, Container, Row, Col } from 'react-bootstrap';

const CallbackPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('Processing your payment, please wait...');
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);

      // Extract individual parameters
      const base64Response = searchParams.get('response');
      const requestDatastring = handleDecode(base64Response);
      const referrerUrl = document.referrer;
      const result = JSON.parse(requestDatastring);

      if (result.errors) {
        console.log("🚀 ~ fetchData ~ requestDatastring.errors:", requestDatastring.errors);
        setMessage("This payment could not be completed because it has already been paid or it expired. Redirecting you to retry...");
        setIsError(true);
        
        setTimeout(() => {
          navigate(-2);
        }, 5000);
        return;
      } else {
        console.log("🚀 ~ fetchData ~ requestDatastring:", requestDatastring);
        
        // Send data to backend API
        const response = await fetch('https://api.zatuxe.com/verve_payment_callback', {
          // const response = await fetch('https://localhost:5001/verve_payment_callback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ IpgcallbackJsonValue: base64Response, referralUrl: referrerUrl })
        });

        if (response.ok) {
          console.log('Data sent successfully to backend!');
          const result = await response.json();
          console.log("🚀 ~ fetchData ~ result:", result)
          window.location.href = result.redirectUrl;
          
        } else {
          console.error('Failed to send data to backend.');
          setMessage('Failed to send data to backend.');
          setIsError(true);
        }
      }
    } catch (error) {
      console.error('Error fetching or sending data:', error);
      setMessage('An unexpected error occurred. You will now be redirected');
      setIsError(true);
      setTimeout(() => {
        navigate(-2);
      }, 5000);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEncode = (input) => {
    try {
      const encodedValue = btoa(input);
      return encodedValue;
    } catch (e) {
      console.error('Error encoding to base64', e);
    }
  };

  const handleDecode = (input) => {
    try {
      const cleanedValue = input.split("%");
      const decodedValue = atob(cleanedValue[0]);
      return decodedValue;
    } catch (e) {
      console.error('Error decoding from base64', e);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row>
        <Col>
          {isError ? (
            <Alert variant="danger" className="text-center">
              <i className="bi bi-exclamation-circle-fill" style={{ fontSize: '2rem' }}></i>
              <div>{message}</div>
            </Alert>
          ) : (
            <div className="text-center">
              <Spinner animation="border" role="status" className="mb-3">
                <span className="sr-only">Loading...</span>
              </Spinner>
              <div>{message}</div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CallbackPage;
